<script>
import { ValidationObserver } from 'vee-validate'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import RenderAvatars from '/~rec/components/render/render-avatars.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useChat } from '/~rec/composables/chat'
import { useBackendValidation } from '/~rec/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'

export default {
  name: 'rec-chat-settings-modal',
  components: {
    RenderAvatars,
    BaseInput,
    BaseButton,
    BaseMdl,
    HeaderMobile,
    ValidationObserver,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    room: {
      type: Object,
      default: null,
    },
    editMembers: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { createRoom, isNewChatProcessCreating } = useChat()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()

    return {
      createRoom,
      isNewChatProcessCreating,
      modal,
      backendErrors,
      processBackendErrors,
      ui,
      validationObserverRef,
    }
  },
  data() {
    return {
      processing: false,
      form: {
        name: '',
        users: [],
      },
    }
  },
  computed: {
    title() {
      return this.room ? this.room.name : this.$attrs.title
    },
  },
  created() {
    const { room } = this

    if (room) {
      this.form = {
        name: room.name,
        users: room.members,
      }
    }
  },
  methods: {
    async onSave() {
      const { form, room } = this

      this.isNewChatProcessCreating = true

      let newRoom = {}
      let payload = {}

      try {
        if (room) {
          if (room.name !== form.name) {
            payload = {
              name: form.name,
              type: 'channel',
            }
            newRoom = await room.edit(payload)
          }
        } else {
          payload = {
            name: form.name,
            userIds: this.users.map((user) => user.id),
          }
          newRoom = await this.createRoom({
            name: form.name,
            userIds: this.users.map((user) => user.id),
          })
        }

        this.$emit('save', newRoom)
      } catch (error) {
        this.processBackendErrors(error, { data: payload })
      } finally {
        this.isNewChatProcessCreating = false
      }
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <base-mdl
      fullscreen="mobile"
      transition="slide-right"
      :width="ui.desktop ? 'sm' : 'screen'"
      :title="title"
      :padding="false"
    >
      <template #header>
        <header-mobile v-if="ui.mobile" :title="title" @back="modal.hide()" />
      </template>
      <div class="flex h-full grow flex-col px-5">
        <div>
          <base-input
            v-model="form.name"
            :validation="{
              rules: 'required',
              name: 'Name Multi-chat',
            }"
            :error="backendErrors.name"
            :maxlength="45"
            :disabled="isNewChatProcessCreating"
            autofocus
            label="Name Multi-chat"
            required
            class="grow-0"
            name="name"
            look="simple"
          />
        </div>
        <render-avatars
          class="mb-5"
          :users="room ? room.activeMembersWithoutMe : users"
          @click="$emit('members')"
        />
        <base-button
          class="mb-[30px] mt-auto w-full"
          :loading="isNewChatProcessCreating"
          @click="handleSubmit(onSave)"
        >
          Save
        </base-button>
      </div>
    </base-mdl>
  </validation-observer>
</template>
